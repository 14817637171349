import { useState } from "react";
import "./Footer.css"
import { Link,useNavigate,NavLink } from "react-router-dom";
import axios from "axios"
import {Spinner } from "react-bootstrap"


function Footer({
    newsletterSection=false
}) {
    const [email, setEmail] = useState("");
    const [success, setSuccess] = useState(false);
    const [error, setError] = useState(false);
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate()
  
    const formSubmitHandler = (e) => {
      e.preventDefault();
      setLoading(true);
      axios
        .post("/send-newsletter", {
          email: email,
          formType: "Message genrated through Newsletter form",
        })
        .then((response) => {
          if (response.data.msg === "success") {
            setError(false);
            setSuccess("Newsletter Subscribed Successfully!");
            setLoading(false);
            setEmail("");
          } else if (response.data.msg === "fail") {
            setSuccess(false);
            setError("Email failed to send, try again by reloading the page.");
            setLoading(false);
            setEmail("");
          }
          })
        }
  return (
    <>
    
    <div className={`footer-container `}>
        <div className=" px-0">
        <div className="form container">
        {newsletterSection && 
            <div className="container-fluid">
                <div className="form-container  gy-5">
                    <div className="">
                        <div 
                            className="text-container text-center d-flex flex-column  gap-3"

                        >
                            <h3 className="body-heading color-dark" style={{textTransform:"uppercase"}}>
                            SUBSCRIBE TO OUR <span className="color-blue">NEWS LETTER</span>
                            </h3>
                            <p className="body-paragraph mb-5">
                            Lorem ipsum dolor sit amet, consectetur adipiscing elit sed faucibus id ultrices nullam viverra
                            </p>
                        </div>
                    </div>
                    <div className=" d-flex align-items-center justify-content-center">
                        <form className='inp-container d-flex align-items-center justify-content-between' onSubmit={formSubmitHandler}>
                            <input 
                                type='text' 
                                placeholder='Email'  
                                value={email}
                                maxLength="36"
                                onChange={(e) => setEmail(e.target.value)}
                            />
                            <button className='inp-btn d-flex align-items-center gap-3'>
                                <span>SUBSCRIBE</span>
                                <img src="/images/icons/btn-bell.svg" className="bell-icon mb-0" alt="icon" />
                                {loading ? (
                                    <Spinner
                                    animation="border"
                                    role="status"
                                    style={{ color: "white", width: "15px", height: "15px", marginLeft: "10px" }}
                                    />
                                ) : (
                                    success ? (
                                    <div >
                                        {setLoading(false)}
                                        {alert(success)}
                                        {setSuccess(false)}
                                    </div>
                                    ) : (
                                    error && (
                                        <div>
                                        {setLoading(false)}
                                        {alert(error)}
                                        {setError(false)}
                                        </div>
                                    )
                                    )
                                )}
                            </button>
                        </form>
                    </div>
                
                
                </div>
            </div>
        }
        </div>
        {/* newsletter Upper section */}
        {/* <div className="line-seprator"></div> */}
        <div className="upper-newsletter-container container-fluid mx-auto mt-2 pt-4 pb-5" >

            <div className="row d-flex align-items-start gy-5 container justify-content-center mx-auto mt-2">
                <div className="col-xl-4 col-lg-4 col-md-6 col-12">
                    <div className="text-container">
                        <div className="newsletter-logo img-fluid mb-4">
                            <img  src="/images/icons/nav-logo-w.png" alt="brand icon" className="img-fluid brand-logo" onClick={()=>{navigate("/")}} style={{cursor:"pointer"}}/>
                        </div>
                        <div className="text-content">
                            <p className="body-paragraph my-3" style={{color:"white"}}>A space dedicated to connection and clarity. Here, you'll find resources, insights, and inspiration to support you as you navigate your journey within.</p>
                        </div>
                        <div className="d-flex align-items-center justify-content-md-start justify-content-center gap-2 w-100 mt-4">
                                <img src="/images/icons/footer-call-icon.svg" alt="icon"/>
                                <div>
                                    <a href="tel:+1 437 328 3990" className="body-paragraph info-conten" style={{color:"white",textDecoration:"none",cursor:"pointer"}}>+1 437 328 3990</a>
                                </div>
                            </div>
                            <div className="d-flex align-items-center justify-content-md-start justify-content-center gap-2  w-100 mt-4">
                                <img src="/images/icons/footer-mail-icon.svg" alt="icon"/>
                                <div>
                                <a href="mailto:Info@cupreadingswc.com" className="body-paragraph info-conten" style={{color:"white",textDecoration:"none",textTransform:"lowercase",cursor:"pointer"}}>Info@cupreadingswc.com</a>
                                    
                                </div>
                            </div>
                    
                    </div>
                </div>
                <div className="col-xl-5 col-lg-4 col-md-6 col-12">
                    <div className="page-links d-flex flex-md-row gap-3 flex-column align-items-md-start align-items-center text-center justify-content-evenly mt-lg-3 mt-0">
                        <div className="text-md-start text-center d-flex flex-column gap-3">
                            {/* <h3 className="footer-title color-dark font-mons">Quick Links</h3> */}
                            {/* <NavLink to={"/"} className="anchor" exact activeClassName="active">
                            HOME    
                            </NavLink> */}
                            <NavLink to={"/about"} className="anchor" exact activeClassName="active">
                            ABOUT
                            </NavLink>
                            <NavLink to={"/services"} className="anchor" exact activeClassName="active">
                            SERVICES
                            </NavLink>
                            <NavLink to={"/pricing"} className="anchor" exact activeClassName="active">
                            PRICING
                            </NavLink> 
                            <NavLink to={"/testimonials"} className="anchor" exact activeClassName="active">
                            TESTIMONIALS
                            </NavLink>
                            <NavLink to={"/blog"} className="anchor" exact activeClassName="active">
                            BLOGS
                            </NavLink>
                            <NavLink to={"/contact"} className="anchor" exact activeClassName="active">
                            CONTACT 
                            </NavLink> 
                        </div>
                        <div className="text-md-start text-center d-flex flex-md-column flex-row  gap-3">
                            {/* <h3 className="footer-title color-dark font-mons mt-4 mt-lg-0">Consultation</h3> */}
                           
                            <NavLink to={"/cookie-policy"} className="anchor" exact activeClassName="active">
                            COOKIE POLICY 
                            </NavLink> 
                            <NavLink to={"/term-of-service"} className="anchor" exact activeClassName="active">
                            TERMS OF SERVICE 
                            </NavLink> 
                            {/* <NavLink to={"/"} className="anchor" exact activeClassName="active">
                            TERMS OF USE 
                            </NavLink>  */}
                            <NavLink to={"/privacy-policy"} className="anchor" exact activeClassName="active">
                            PRIVACY POLICY
                            </NavLink> 
                           
                            
                        </div>
                    </div>
                </div>
                <div className="col-xl-3 col-lg-4 col-12">
                    <div className="info-container">
                    {/* <h3 className="footer-title color-dark font-mons mb-5 text-center text-lg-start">Get in Touch</h3> */}
                       <div className="d-flex flex-column align-items-center justify-content-start gap-4 ">
                            <h3 className="body-heading text-center text-lg-start" style={{color:'white',fontSize:"35px"}}>Subscribe To Our News Letter</h3>
                            <form className='inp-container d-flex  justify-content-between w-100' onSubmit={formSubmitHandler}>
                            <input 
                                type='text' 
                                placeholder='Email'  
                                value={email}
                                maxLength="36"
                                onChange={(e) => setEmail(e.target.value)}
                            />
                            <button className='inp-btn d-flex align-items-center gap-3 px-4'>
                                {/* <span>SUBSCRIBE</span> */}
                                <img src="/images/icons/btn-bell.svg" className="bell-icon mb-0" alt="icon" />
                                {loading ? (
                                    <Spinner
                                    animation="border"
                                    role="status"
                                    style={{ color: "white", width: "15px", height: "15px", marginLeft: "10px" }}
                                    />
                                ) : (
                                    success ? (
                                    <div >
                                        {setLoading(false)}
                                        {alert(success)}
                                        {setSuccess(false)}
                                    </div>
                                    ) : (
                                    error && (
                                        <div>
                                        {setLoading(false)}
                                        {alert(error)}
                                        {setError(false)}
                                        </div>
                                    )
                                    )
                                )}
                            </button>
                        </form>
                            <div className="d-flex align-items-start justify-content-center justify-content-lg-start gap-3  w-100">
                                <img style={{cursor:"pointer"}} onClick={()=>{window.open("https://www.facebook.com/cupreadingswc/")}} src="/images/icons/footer-fb-icon.svg" alt="icon"/>
                                <img style={{cursor:"pointer"}} onClick={()=>{window.open("https://www.instagram.com/cupreadingswc/")}} src="/images/icons/footer-insta-icon.svg" alt="icon"/>
                                {/* <img src="/images/icons/footer-twiter-icon.svg" alt="icon"/> */}
                                {/* <img src="/images/icons/footer-linked-icon.svg" alt="icon"/> */}
                            </div>
                            {/* <div className="d-flex align-items-center justify-content-lg-start justify-content-center gap-2 w-100">
                                <img src="/images/icons/footer-location-icon.svg" alt="icon"/>
                                <div>
                                    
                                    <p className="body-heading info-content mb-0" style={{textTransform:"capitalize"}}>Milton, Ontario, Canada</p>
                                </div>
                            </div> */}
                           
                       </div>
                    </div>
                </div>

            </div>
            
           
        </div>
        </div>
        {/* div for border lining */}

        {/* Lower sections */}

        <div className="lower-section text-center py-3">
            <div className="lower-container container flex-column gap-1 flex-md-row justify-content-md-between justify-content-center">
                <p className="mb-0">© 2024 | Inner Find | All rights reserved</p>
                <p className="mb-0">Made With ❤️ By <a href="https://www.creativesquad.ca" target="blank">Creative Squad </a></p>
            {/* <div className="social-link d-flex align-items-center justify-content-center gap-3 mt-2">
                <a href="https://www.tiktok.com/@mes.decors " target="blank" rel="noopener noreferrer">
                    <img src="/images/icons/tiktok-icon.png" alt="icon" className="social-icons"/>
                </a>
                <a href="https://www.instagram.com/mesdecors.homestaging/ " target="blank" rel="noopener noreferrer">
                    <img src="/images/icons/insta-icon.png" alt="icon" className="social-icons"/>
                </a>
                <a href="https://www.facebook.com/mesdecors.homestaging" target="blank" rel="noopener noreferrer">
                    <img src="/images/icons/fb-icon.png" alt="icon" className="social-icons"/>
                </a>
                <a href="https://www.linkedin.com/company/mes-d-cors-inc/ " target="blank" rel="noopener noreferrer">
                    <img src="/images/icons/li-icon.png" alt="icon" className="social-icons"/>
                </a>
                



            </div> */}
            </div>
        </div>
    </div>
    </>
  )
}

export default Footer