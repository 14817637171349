import React, { useState, useRef, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import "./Header.css";

const Header = ({ darkBg = false }) => {
  const [menuState, setMenuState] = useState(false);
  const [changeArrowcolor, setChangeArrowColor] = useState(false);
  const [changeArrowcolorDec, setChangeArrowColorDec] = useState(false);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [isDropdownOpenDesk, setIsDropdownOpenDesk] = useState(false);
  const [isDropdownOpenDec, setIsDropdownOpenDec] = useState(false);
  const [isDropdownOpenDeskDec, setIsDropdownOpenDeskDec] = useState(false);
  const navigate = useNavigate()
  const clickCountRef = useRef(0);
  const dropdownRef = useRef();
  const dropdownRefDec = useRef();

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target) && isDropdownOpenDesk) {
        if (!event.target.closest('.app')) {
          setIsDropdownOpenDesk(false);
        }
      }
      if (dropdownRefDec.current && !dropdownRefDec.current.contains(event.target) && isDropdownOpenDeskDec) {
        if (!event.target.closest('.app')) {
          setIsDropdownOpenDeskDec(false);
        }
      }
    };

    document.addEventListener('click', handleClickOutside);

    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, [isDropdownOpenDesk, isDropdownOpenDeskDec]);


  const onClickService = (e) => {
    e.preventDefault();

    clickCountRef.current++;
    if (clickCountRef.current === 1) {
      setIsDropdownOpen(true);
    }
    else if (clickCountRef.current === 2) {
      setIsDropdownOpen(false)
      clickCountRef.current = 0;
    }
  };
  const onClickDec = (e) => {
    e.preventDefault();

    clickCountRef.current++;
    if (clickCountRef.current === 1) {
      setIsDropdownOpenDec(true);
    }
    else if (clickCountRef.current === 2) {
      setMenuState(false)
      setIsDropdownOpenDec(false)
      navigate("/decorating");
      clickCountRef.current = 0;
    }
  };

  const menuClick = () => {
    setMenuState((prev) => !prev);
  };

  return (
    <div className={`header w-100 py-xl-4 py-0`}
      style={darkBg ? { backgroundColor: "transparent" } : {}}

    >
      <div
        className="header-wrapper container"
      >
        <nav className="navbar d-lg-flex flex-lg-row d-block">
          <nav className="navbar-container px-xl-0 px-2 py-2">
            <div className="nav-elements d-flex justify-content-between align-items-center px-xl-2" >
              <input className="d-none" type="checkbox" id="menu-toggle" />
              <img src={`/images/icons/nav-logo${darkBg ? "-b" : "-b"}.png`} className="nav-logo" onClick={() => { navigate("/") }} style={{ cursor: "pointer" }} alt='icon' />
              <nav>
                <ul
                  className="nav-list d-lg-flex gap-xl-4 gap-lg-3 align-items-center d-none main-nav"
                  id="js-menu"
                >
                  {/* <li>
                    <Link to={"/"} className={`nav-links font-lato ${darkBg && "color-dark"}`}>
                      Home
                    </Link>
                  </li> */}
                  <li>
                    <Link to={"/about"} className={`nav-links font-lato ${darkBg && "color-dark"}`}>
                      About
                    </Link>
                  </li>
                  {/* <li>
                    <Link to={"/services"} className={`nav-links font-lato ${darkBg && "color-dark"}`}>
                      Services
                    </Link>
                  </li> */}
                  <div className="navigation-dropdown" ref={dropdownRef}>
                  <li onMouseEnter={()=>{setIsDropdownOpenDesk(true);setIsDropdownOpenDeskDec(false)}} >

                      <div className="dropdown-trigger d-flex align-items-center" onMouseOver={()=>{setChangeArrowColor(true)}} onMouseOut={()=>{setChangeArrowColor(false)}}>
                          <Link to={"/services"} className={`nav-links font-lato ${darkBg && "color-dark"}`} onClick={()=>{setIsDropdownOpenDesk(false)}} style={{color:`${changeArrowcolor ? "#60645C":"#60645C"}`}}>Services</Link>
                          <img src={`/images/icons/drop-icon-${darkBg ? "b":"b"}.png`} className={isDropdownOpenDesk ? "rotate-icon arrow-icon":"rotate-back arrow-icon" } alt='icon'/>
                      </div>
                      <div className={`dropdown-content ${isDropdownOpenDesk ? 'open' : 'none-delay'}`} onMouseEnter={()=>{setIsDropdownOpenDesk(true)}} onMouseLeave={()=>{setIsDropdownOpenDesk(false)}}>
              
                        <a className={`nav-links font-lato ${darkBg && "color-dark"}`} onClick={()=>{setIsDropdownOpenDesk(false)}}>
                          <p className="mb-0">Course coming soon</p>
                        </a>
                       
                      </div>
                    </li>
                    </div>

                  <li>
                    <Link to={"/pricing"} className={`nav-links font-lato ${darkBg && "color-dark"}`}>
                      Pricing
                    </Link>
                  </li>
                  <li>
                    <Link to={"/testimonials"} className={`nav-links font-lato ${darkBg && "color-dark"}`}>
                    Testimonials 
                    </Link>
                  </li>

                 
                  <li>
                    <Link to={"/contact"} className={`nav-links font-lato ${darkBg && "color-dark"}`}>
                      Contact
                    </Link>
                  </li>
                </ul>
              </nav>

              <label
                value="menu-toggle"
                onClick={menuClick}
                className="responsive-menu-btn d-lg-none d-inline"
              >
                <img
                  src={`/images/icons/hamburger-menu${darkBg ? "-b" : "-b"}.png`}
                  className="img-fluid menu-btn"
                  alt="menu"
                />
              </label>

              <div className="nav-buttton ml-5 d-lg-flex d-none gap-3 extras-btns">

                <button
                  className={`${darkBg ? " genral-btn-light" : "hero-genral-btn"}  font-lato`}
                  onClick={() => { window.open("https://www.waitlistr.com/lists/138d1ff7/cup-reading?ref=lnk") }}
                >
                  waiting list
                </button>
                <button
                  className={`${darkBg ? " genral-btn-light" : "hero-genral-btn"}  font-lato`}
                  onClick={() => { window.open("https://calendly.com/innerfind/") }}
                >
                  Book An Appointment
                </button>
              </div>
            </div>
          </nav>

          {/* Dropdown in responsive mode */}
          <nav className="responsive-popup-menu w-100">
           
          </nav>
        </nav>
      </div>

      {/* mobile header */}
      <nav className="responsive-popup-menu-mob">
            <div className={` d-lg-none nav_slide ${menuState? "nav_slide-in":"nav_slide-out"} `}
            style={{ background:"white", width:"100%"}}
            >
                <label
                value="menu-toggle"
                onClick={menuClick}
                className="responsive-menu-btn d-lg-none d-block pb-4 d-flex flex-row align-items-center justify-content-between px-4"
              >
                <img src={`/images/icons/nav-logo${darkBg ? "-b" : "-b"}.png`}  style={{width:"100px",height:"40px"}} onClick={() => { navigate("/") }} alt='icon' />
                <img
                  src={`/images/icons/cross-menu${darkBg ? "" : ""}.png`}
                  className="img-fluid menu-bt"
                  alt="menu"
                />
              </label>
              <ul
                className="resp-nav-list d-lg-none  gap-5 hs-dropdown [--trigger:hover] px-4 pt-2"
                style={{ width:"100%",background:"white"}}
              >
              
                <li style={{borderBottom:"1px solid grey"}} className="pb-2">
                  <Link to={"/"} className={`nav-links font-lato ${darkBg && "color-dark"}`}>
                    HOME
                  </Link>
                </li>
                <li style={{borderBottom:"1px solid grey"}} className="pb-2">
                  <Link to={"/about"} className={`nav-links font-lato ${darkBg && "color-dark"}`}>
                    ABOUT
                  </Link>
                </li>
                {/* <li>
                  <Link to={"/services"} className={`nav-links font-lato ${darkBg && "color-dark"}`}>
                    SERVICES
                  </Link>
                </li> */}
                <li>
                <div className="navigation-dropdown w-100"  >
                  <div className="dropdown-trigger d-flex align-items-center pb-2" style={{color:"black",borderBottom:"1px solid grey"}} >
                      <Link to="/services" className={`nav-links font-lato ${darkBg && "color-dark"}`} >SERVICES</Link>
                      <img src='/images/icons/drop-icon-b.png' className={isDropdownOpen ? "rotate-icon arrow-icon":"rotate-back arrow-icon" } alt='icon' onClick={onClickService} />
                  </div>
                  <div className={` m-0 nav_slide-sub ${isDropdownOpen ? 'nav_slide-in-sub' : 'nav_slide-out-sub'}`} >
                    
                    <a className={`nav-link font-lato ${darkBg && "color-dark"}`} onClick={()=>{setIsDropdownOpen(false); setMenuState(false)}}>
                      <p className="mb-0 mt-2">COURSE COMING SOON</p>
                    </a>
                  
                    
                  </div>
                </div>
                </li>
                <li style={{borderBottom:"1px solid grey"}} className="pb-2">
                  <Link to={"/pricing"} className={`nav-links font-lato ${darkBg && "color-dark"}`}>
                    PRICING
                  </Link>
                </li>
                <li style={{borderBottom:"1px solid grey"}} className="pb-2">
                  <Link to={"/testimonials"} className={`nav-links font-lato ${darkBg && "color-dark"}`}>
                  TESTIMONIALS 
                  </Link>
                </li>
                <li style={{borderBottom:"1px solid grey"}} className="pb-2">
                  <Link to={"/blog"} className={`nav-links font-lato ${darkBg && "color-dark"}`}>
                  BLOGS 
                  </Link>
                </li>
                <li >
                  <Link to={"/contact"} className={`nav-links font-lato ${darkBg && "color-dark"}`}>
                    CONTACT
                  </Link>
                </li>

                



              </ul>
              <div className="flex-column flex-wrap d-flex gap-4 extras-btns justify-content-start align-items-start px-4" style={{width:"fit-content"}}>

                <button
                  className="genral-btn-light font-lato"
                  style={{
                    backgroundColor:"#B1B6AC",
                    border:"1px solid #B1B6AC"
                  }}
                  onClick={() => { window.open("https://www.waitlistr.com/lists/138d1ff7/cup-reading?ref=lnk") }}
                >
                  Waiting List
                </button>
                <button
                  className="genral-btn-light font-lato"
                  style={{
                    backgroundColor:"#B1B6AC",
                    border:"1px solid #B1B6AC"
                  }}
                  onClick={() => { window.open("https://calendly.com/innerfind/") }}
                >
                  BOOK AN APPOINTMENT
                </button>
              </div>
            </div>
          </nav>
    </div>
  );
};

export default Header; 