import React from 'react'
import "./ServiceSplitContentFirst.css"

function ServiceSplitContentFirst( {
    bgImg = null,
    heading = "",
    headingBg = null,
    content1 = "",
    content2 = "",
    stylishHeading = "",
    splitText = null,
    id = "",
    bgColor=null,
    textColor=null
}) {
    const paragraphsOne = content1.split('\n').map((paragraph, index) => <p key={index} className={`body-paragraph my-3 mx-auto`} style={{color:`${textColor ? textColor:""}`}}>{paragraph}</p>);
    const paragraphsTwo = content2.split('\n').map((paragraph, index) => <p key={index} className={`body-paragraph my-3 mx-auto`} style={{color:`${textColor ? textColor:""}`}}>{paragraph}</p>);
    return (
        <div
            className='ServiceSplitContentFirst-container standard-padding-space pt-0 pb-2'
            style={{ backgroundImage: `url("${bgImg}")`,backgroundColor:`${bgColor?bgColor:"transparent"}` ,overflowX:"hidden",overflowY:"hidden"}}
            id={id}
        >
            <div
                className='container mb-5 text-start'>
                <div className='row d-flex flex-lg-row flex-column-reverse'>
                    <div className='col-xl-8 col-lg-7 px-5 py-5 main-card' style={{border:"7px solid #F6EFEE"}}>
                    <h3
                        className='body-heading mb-3 '
                        style={{color:`${textColor ? textColor:""}`}}
                    >
                        {heading}
                    </h3>
                    <p className='body-paragraph my-3 main-par'  style={{color:`${textColor ? textColor:""}`}}>{paragraphsOne}</p>

                    {
                        stylishHeading &&
                        <h3 className='my-4 font-Aervish stylish-font-text py-3' style={{color:`${textColor ? textColor:"#B1B6AC"}`}}>{stylishHeading}</h3>
                    }

                    {
                        splitText &&
                        <div className='row text-align-start'>
                            {
                                splitText.map((ele) => {
                                    return (
                                        <div className='col-12 text-start'>
                                            <h3 className='body-heading' style={{ fontSize: "25px",color:`${textColor ? textColor:""}` }}>{ele.title}</h3>
                                            <p className='body-paragraph' style={{color:`${textColor ? textColor:""}`}}>{ele.content}</p>
                                        </div>
                                    )
                                })
                            }
                        </div>
                    }
                    <div className='line-seprator mt-2' style={{
                        borderTop:`1px solid ${textColor ? textColor:"black"}`
                    }}></div>
                    <p className='body-paragraph mt-4 main-para ' style={{color:`${textColor ? textColor:""}`}}>{paragraphsTwo}</p>
                    </div>

                    <div className='col-xl-4 col-lg-5  px-0' >
                        <img src='/images/creatives/services-split-first-section.jpg' alt='service side' className='split-img' />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ServiceSplitContentFirst