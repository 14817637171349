import React,{useState,useEffect} from 'react'
import BlogInnerHeroBanner from '../component/BlogInnerHeroBanner/BlogInnerHeroBanner'
import BlogContent from '../component/BlogContent/BlogContent'
import Footer from "../component/Footer/Footer"
import axios from 'axios'
import { Spinner } from 'react-bootstrap'
import { useParams } from "react-router";
import { Helmet } from "react-helmet";
import BlogDetails from '../component/BlogDetails/BlogDetails'
import HeroBanner from '../component/Common Banner Component/HeroBanner'
import HeroBannerDark from '../component/Common Banner Component Dark/HeroBannerDark'


const newsData=[ 
  {
    blog_creative: "/images/creatives/blog-thumb-1.jpg",
    main_heading: "Discovering Your Inner Self",
    date:"",
    slug_url: "1",
    catagory:"RESOURCES",
    published_date:"7 Jan 2024",
    brief_paragraph:"Finding your inner self is a journey of self-discovery and self-awareness. It involves exploring your thoughts, emotions, beliefs, values, and desires to gain a deeper understanding of who you are as an individual. Here are some steps that may help you in this process:",
    blogs_content:{heading:"",
      paragraph:`<div class="">
			



<p>Self-reflection: Take time to reflect on your life, experiences, and the choices you’ve made. Consider your strengths, weaknesses, and the things that make you unique.</p>



<p>Journaling: Keeping a journal can be a valuable tool for self-discovery. Write down your thoughts, feelings, and experiences regularly. This can help you uncover patterns in your behavior and gain insight into your inner world.</p>



<p>Meditation and mindfulness: Practicing meditation and mindfulness can help you become more aware of your thoughts and emotions. It can also provide a sense of inner peace and clarity.</p>



<p>Seek feedback: Ask friends, family, or trusted individuals for honest feedback about your strengths and weaknesses. Sometimes, others can see aspects of yourself that you may not be aware of.</p>



<p>Explore your interests: Engage in activities and hobbies that you are passionate about. Your interests can provide clues about your inner self and what brings you joy.</p>



<p>Set goals and values: Define your long-term goals and values. Knowing what you stand for and what you want to achieve can help you align your actions with your inner self.</p>



<p>Embrace self-acceptance: Accept yourself, flaws and all. Self-acceptance is a crucial part of finding your inner self. It involves being kind and compassionate to yourself.</p>



<p>Seek professional help: If you’re struggling with self-discovery or facing emotional challenges, consider seeking the guidance of a therapist or counselor. They can provide you with valuable insights and tools for self-exploration.</p>



<p>Read and learn: Explore literature and resources on personal development, psychology, and philosophy. Reading can provide different perspectives and inspire self-reflection.</p>



<p>Embrace change: Be open to change and growth. As you learn more about your inner self, you may find that your goals, values, and interests evolve over time.</p>



<p>Remember that finding your inner self is a lifelong journey, and it’s okay not to have all the answers immediately. Be patient with yourself, and enjoy the process of self-discovery. It’s a valuable endeavor that can lead to greater self-awareness and a more fulfilling life.</p>
		</div>`}
  },
  {
    blog_creative: "/images/creatives/blog-thumb-2.jpg",
    main_heading: "Understanding Intuition",
    date:"",
    slug_url: "2",
    catagory:"RESOURCES",
    published_date:"12 Jan 2024",
    brief_paragraph:"Intuition is the ability to understand or know something without the need for conscious reasoning or analytical thought. It’s often described as a “gut feeling” or an instinctive sense of something being true or correct. Intuition can be seen as a form of knowledge or insight that arises from a combination of your past experiences, knowledge, and subconscious processing of information.",
    blogs_content:{heading:"",
      paragraph:`<div class="">
			

<p>Here are a few key points about intuition:</p>



<p>Unconscious Processing: Intuition often operates at a subconscious level, drawing on your brain’s ability to process and recognize patterns and associations that you may not be consciously aware of.</p>



<p>Quick Decision-Making: Intuition can be particularly useful in situations where you need to make rapid decisions or judgments. It can help you arrive at a conclusion without the need for lengthy analysis.</p>



<p>Expertise and Experience: Intuition tends to be more accurate when a person has expertise or significant experience in a particular domain. This is because their subconscious mind has absorbed a wealth of relevant information over time.</p>



<p>Subjective and Error-Prone: While intuition can be a valuable tool, it is not infallible. It can be influenced by biases, emotions, and incomplete information, leading to errors in judgment.</p>



<p>Cultural and Personal Differences: Intuition can vary from person to person and across different cultures. What one person intuitively feels may not be the same as another person’s intuitive sense of a situation.</p>



<p>Combination with Rational Thinking: Intuition is not a substitute for rational thinking and analysis. Often, the best decision-making process involves a balance between intuition and conscious, logical reasoning.</p>



<p>In summary, intuition is a cognitive process that allows individuals to make quick, often subconscious, judgments and decisions based on their accumulated knowledge, experiences, and pattern recognition. It can be a valuable tool in various aspects of life, but it should be used alongside critical thinking and analysis to make well-informed choices.</p>
		</div>`
    }
  },
  {
    blog_creative: "/images/creatives/blog-thumb-3.jpg",
    main_heading: "Use Your Intuition",
    date:"",
    slug_url: "3",
    catagory:"RESOURCES",
    published_date:"3 Aug 2024",
    brief_paragraph:"Using your intuition involves tapping into your inner wisdom and making decisions or judgments based on gut feelings, instincts, or a sense of knowing, rather than relying solely on logical reasoning. Here are some steps to help you use your intuition effectively:",
    blogs_content:{heading:"",
      paragraph:`<div class="">



<p>Quiet your mind: Find a quiet, peaceful place where you can relax and clear your mind of distractions. Meditation, deep breathing exercises, or simply taking a moment to be still can help calm your mind and make it more receptive to intuitive insights.</p>



<p>Pay attention to your body: Intuition often manifests as physical sensations in your body. Pay close attention to how your body responds to different situations or decisions. Do you feel tension or discomfort in your chest or stomach, or do you experience a sense of lightness and ease when thinking about a particular choice?</p>



<p>Trust your initial feelings: When faced with a decision or situation, your initial gut reaction is often your intuition speaking. Trust these initial feelings, even if they seem to go against conventional wisdom or logic. Intuition can be a powerful guide.</p>



<p>Practice self-awareness: Understanding your own emotions and biases is essential for using intuition effectively. Be aware of any personal biases, fears, or desires that may influence your intuitive responses.</p>



<p>Seek solitude and reflection: Intuition often arises when you give yourself time and space to reflect on a problem or situation. Take walks in nature, journal your thoughts and feelings, or engage in activities that promote self-reflection.</p>



<p>Ask open-ended questions: When facing a decision, ask yourself open-ended questions like, “What do I truly want?” or “What feels right in this situation?” These questions can help you access your intuitive insights.</p>



<p>Learn from past experiences: Reflect on past situations where you followed your intuition and had positive outcomes. Conversely, think about times when you ignored your intuition and regretted it. Use these experiences to build confidence in your intuition.</p>



<p>Practice and refine your intuition: Like any skill, intuition can be developed and honed over time. The more you practice listening to your inner guidance and acting on it, the more in tune you will become with your intuition.</p>



<p>Balance with logic: While intuition is valuable, it should be used in conjunction with logic and rational thinking, especially in situations that require careful analysis or critical decision-making. Combining intuition with reason can lead to well-rounded choices.</p>



<p>Be patient: Developing your intuition is a journey, and it may not always lead to immediate answers or perfect outcomes. Trust the process and be patient with yourself as you learn to rely on your intuition more effectively.</p>



<p>Remember that intuition is a personal and subjective form of guidance. It may not always be right, but it can provide valuable insights and alternative perspectives that complement your rational decision-making process. Trusting your intuition and learning from the results can help you make better decisions in various aspects of your life.</p>
		</div>`
    }
  },
]

function BlogInner() {
  const [recentBlogs, setRecentBlogs] = useState([]);
  
  const [loading, setLoading] = useState(false);
  
  const id  = useParams().id;
  const [blogsData, setBlogsData] = useState(newsData[id-1]);

  let blogAPICalledId = false;
  let allBlogsCalled = false;

  const getAllBlogs = async () => {
    if (allBlogsCalled) return;
    allBlogsCalled = true;

    setLoading(true);
    
    axios
      .get(`/blogs`, {})
      .then((res) => {
        if (res.data.status === "success") {
          let Updated_recent_blogs = [];
          
          let blog = res.data.data.find((item) => item.slug_url == id);
          setBlogsData(blog);

          res.data.data.forEach((item) => {
            Updated_recent_blogs.push({
              id: item.blog_id,
              slug_url: item.slug_url,
              logo: item.blog_image,
              blog_description: item.title,
              date: item.published_date,
            });
          });
          setRecentBlogs(Updated_recent_blogs.slice(0, 2));
          setLoading(false);
        }
      })
      .catch((err) => {
        console.log(err);
        console.log("err");
        setBlogsData(null)
        setLoading(false);
      });
  };

  useEffect(() => {
    // getAllBlogs();
  }, [id]);



  return (
    <>
    {loading  ? (
          <div
            style={{ width: "100%", height: "100vh" }}
            className="d-flex justify-content-center align-items-center"
          >
            <Spinner
              style={{ color: "black", width: "120px", height: "120px" }}
            />
          </div>
        ) :
        (
          <>
            {/* {blogsData ?

              <BlogInnerHeroBanner
                heading={blogsData.main_heading}
                date={blogsData.published_date}
              />
              :
              <BlogInnerHeroBanner/>
            } */}
            
            <HeroBannerDark
              subHeading=""
              prePara={`Posted By: Inner Find | ${blogsData.published_date}`}
              Heading={blogsData.main_heading}
              nextLineSpanHeading=""
              Paragraph=""
              headingBgImg="/images/icons/scratch-hero-bg.svg"
              btn={false}
              sideLinks={false}
              bgVideo={false}
              bgColor="#B1B6AC"
              paddingBottom={false}
              />
          
            {blogsData && 
              <>
                <Helmet>
                  {blogsData?.metaTitle && <title>{blogsData.metaTitle}</title>}
                  {blogsData?.metaDescription && <meta name='description' content={blogsData.metaDescription} />}
                  {blogsData?.canonicalLink && <link rel="canonical" href={blogsData.canonicalLink}></link>}
                </Helmet>
                

                {/* <img src="/images/creatives/blog-innner.jpg" className='img-fluid' alt='icon'/> */}

                {/* <BlogContent
                  blogsData={blogsData.blogs_content}
                  text={blogsData.brief_paragraph}
                  recentBlogs={recentBlogs}
                /> */}

                <BlogDetails
                   blogsData={blogsData.blogs_content}
                   text={blogsData.brief_paragraph}
                   bannerImg={blogsData?.blog_creative}
                />

                {/* <BlogInnerLatest
                  latestNewsData={recentBlogs}
                /> */}
            </>}
            
            </>
          )}

        <Footer/>
    </>
  )
}

export default BlogInner