import React, { useEffect, useState } from 'react'
import HeroBanner from '../component/Common Banner Component/HeroBanner'
import TextContent from '../component/TextContent/TextContent'
import Footer from '../component/Footer/Footer'
import { Helmet } from 'react-helmet'
import CenteredText from '../component/CenteredText/CenteredText'
import FaqSection from '../component/FaqSection/FaqSection'
import BlogCard from '../component/BlogCard/BlogCard'
import CustomSlider from '../component/CustomSlider/CustomSlider'
import PlainText from '../component/PlainText/PlainText'
import ServiceSplitContent from '../component/ServiceSplitContent/ServiceSplitContent'
import ServiceSplitContentFirst from '../component/ServiceSplitContentFirst/ServiceSplitContentFirst'
import ServiceSplitContentSecond from '../component/ServiceSplitContentSecond/ServiceSplitContentSecond'

const servicesData = [
  // {
  //   title: "What to Expect in an in-Person Reading",
  //   btnText: "LEARN MORE",
  //   btnRef: "/services?id=what-to-expect-in-person"
  // },
  // {
  //   title: "What to Expect in a Remote Reading",
  //   btnText: "LEARN MORE",
  //   btnRef: "/services?id=what-to-expect-in-remote"
  // },
  {
    title: "One-on-One Intuitive Readings",
    btnText: "LEARN MORE",
    thumbImg:"/images/creatives/One-on-One-servcie.jpg",
    iscardbg:true,
    btnRef: "/services?id=one-on-one-intutive"
  },
  {
    title: "Partnered Intuitive Readings",
    thumbImg:"/images/creatives/Partnered-servcie.jpg",
    iscardbg:true,
    btnText: "LEARN MORE",
    btnRef: "/services?id=partner-intutive"
  },

]

const faqData = [
  {
    "title": "How do you read energy and pick up on information during a reading?",
    "content": "I am clairvoyant and tap into your energy through a combination of hearing your voice, seeing your image, and knowing your name. For more information on how I discovered my intuitive abilities, please read my story, <a href=\"https://innerfind.ca/about/\"><u>here</u></a>."
  },
  {
    "title": "What is the purpose of a reading?",
    "content": "Readings are meant to offer clarity, direction, healing, and inner peace."
  },
  {
    "title": "How should I prepare for a reading?",
    "content": "Please don’t be nervous about receiving a reading. You are about to receive helpful information that will be healing for you to know, so be excited for the messages you are about to receive! Showing up with a lot of stress, or strong/heavy emotions can block the necessary flow of energy to do a reading.<br/> Feel free to come to a session with any specific questions you have in mind, and if you are asking about a particular person or people, please bring a picture of them as well.<br/> If I am unable to read your energy within the first 10-15 minutes of a session, I will stop the reading and let you know. This doesn’t occur often, but as I previously mentioned, this can happen if your energy is closed off due to strong emotions, or if you are trying to test my abilities and not being honest in our interaction."
  },
  {
    "title": "Can you connect to my departed loved one(s)?",
    "content": "I don’t offer readings specifically for this purpose. Having said that if it is meant to come through it will. I have had sessions with people where departed loved ones do reach out and connect, and I am able to pick up on their energy."
  },
  {
    "title": "Can you tell me about past lives that I’ve lived?",
    "content": "Similar to connecting with departed loved ones, I do not offer readings solely for this purpose, however it can come up if it’s meant to."
  },
  {
    "title": "Can you provide time frames of when certain things are going to happen?",
    "content": "In certain cases, time frames can show up in a reading. But please be mindful of the fact that you are born with free will, and time frames can change based on the actions you take. Because of this, time frames are never 100% accurate."
  },
 
  {
    "title": "Is a remote reading as effective as an in person reading?",
    "content": "Yes, remote readings are just as effective as in person readings."
  },
  {
    "title": "Can you provide a recording of my reading?",
    "content": "I do not offer a recording of our session, and kindly ask that you do not record it either as I find that it throws off my energy, and I am unable to see clearly. However, I do encourage you to bring a notebook and a pen if you want to jot down notes."
  },
  {
    "title": "What can I expect after a reading?",
    "content": "After a session it is likely that you will be processing the information that came up in your reading. Sometimes if a person comes with a lot of emotions and concerns, they may feel that they need extra rest for 1-3 days afterwards, as they process and heal. Be gentle with yourself during this time, and give yourself extra rest and care if you need it."
  },
  {
    "title": "How often should I get a reading?",
    "content": "This totally depends on you! I generally recommend that readings are spaced out for energy to heal, but of course each individual moves through this process at their own pace. Some people find it helpful to do a reading as often as once a week to check in and see how energy is flowing, while others prefer to do a reading every 2-6 months, and some, once a year. You will know when the time is right for you, and I encourage you to schedule a reading whenever you feel it is needed on your end."
  },
  {
    "title": "How do I pay?",
    "content": "If you are located in Canada, payment can be sent prior to the reading via e-transfer to <a href=\"mailto:christinet@innerfind.ca\">christinet@innerfind.ca</a>. If you are located outside of Canada, payment can be sent through PayPal. In this case, I will provide you with the PayPal link once your session is booked."
  },
  {
    "title": "What if I am unable to show up for my reading?",
    "content": "If you are unable to make it to a reading, please don’t feel bad! Letting me know as soon as you can is always helpful and appreciated. If I am unable to show up to our reading for any reason, I will also let you know as soon as possible, and we can always reschedule for another day."
  },
  {
    "title": "Can readings be offered in any other languages?",
    "content": "Readings can be done in English or in Arabic."
  },
  {
    "title": "What if I don’t want something to happen that you mention in my reading?",
    "content": "You can always change your future instantly through making a different decision for yourself, choosing to live in a different way, or simply by moving in a new direction. Nothing is set in stone. Please remember that you have free will, energy is always shifting and moving based on YOU. Readings are meant to provide you with insight, not to choose your life for you."
  },
  {
    "title": "How do I book a reading?",
    "content": `I’d love to work with you and explore the insights that await. To make scheduling as easy as possible, please use the link below to choose a time that fits your schedule: <br> <a href="https://calendly.com/innerfind">Calendly - Inner Find - Cup Readings with Christine</a> <br></br>I look forward to connecting with you and supporting you on your journey. <br><strong> ***Please be aware that I am currently booking 3 months in advance.</strong>`
  },
  {
    "title": "I have another question that wasn’t answered here",
    "content": "If you have any other questions, please feel free to reach out and connect. You can email me at <a href=\"mailto:Info@cupreadingswc.com\">Info@cupreadingswc.com</a> or give me a call at <a href='tell:437-328-3990'>437-328-3990</a>."
  }
]


function Service() {
  const urlParams = new URLSearchParams(window.location.search);
  const [specificSection, setSPecificSection] = useState(urlParams.get('id'))
  useEffect(() => {
    const specialComponentId = urlParams.get('id');
    if (specialComponentId == "what-to-expect-in-person") {

      const specialComponent = document.getElementById("what-to-expect-in-person");
      if (specialComponent) {
        setSPecificSection(specialComponentId)
        specialComponent.scrollIntoView({ behavior: 'smooth' });
      }
    }
    else if (specialComponentId == "what-to-expect-in-remote") {

      const specialComponent = document.getElementById("what-to-expect-in-remote");
      if (specialComponent) {
        setSPecificSection(specialComponentId)
        specialComponent.scrollIntoView({ behavior: 'smooth' });
      }
    }
    else if (specialComponentId == "one-on-one-intutive") {

      const specialComponent = document.getElementById("one-on-one-intutive");
      if (specialComponent) {
        setSPecificSection(specialComponentId)
        specialComponent.scrollIntoView({ behavior: 'smooth' });
      }
    }
    else if (specialComponentId == "partner-intutive") {

      const specialComponent = document.getElementById("partner-intutive");
      if (specialComponent) {
        setSPecificSection(specialComponentId)
        specialComponent.scrollIntoView({ behavior: 'smooth' });
      }
    }
  }, [specificSection]);
  return (
    <div>
      <Helmet>
        <title>

        </title>
        <meta
          name='description'
          content=""
        />
      </Helmet>


      <HeroBanner
        subHeading=""
        Heading=""
        nextLineSpanHeading=""
        Paragraph=""
        bgImg="/images/creatives/service-hero-bg-new.jpg"
        btn={false}
        sideLinks={false}
        bgVideo={false}
        // bgImgLg={"/images/creatives/service-hero-bg-new.jpg"}
        // mobileBgContain={true}
        // bgImgLg={"/images/creatives/service-hero-bg-mob-new.jpg"}
        bgImgLg={"/images/creatives/service-hero-bg-mob-new-n.jpg"}
        // autoMinHeight={true}
      />

      <PlainText
        heading={"What is a Cup Reading?"}
        content={"Cup reading is an ancient art used to read your energy and give you insight around any questions you may have about your life. This often includes relevant information about your past, present, and future."}
        btnText={"Book An Appointment"}
        btn={true}
        btnRefTel={true}
        bgImg={""}
        wideContent={true}
        sliderCardBefore={servicesData}
        btnRefState={setSPecificSection}
        // headingBg={"/images/icons/plain-text-heading-bg-services.svg"}
      />

      <div className='container mb-4'>
        <div className='py-3 px-xl-4 px-3' style={{ backgroundColor: "#B1B6AC" }}>
          <CenteredText
            title='My Method '
            content='Readings are a safe space for you to be yourself and receive guidance based on what I am picking up from your energy. It is my intention that we both enter the session with mutual respect. I always aim to communicate the insights I receive with deep compassion and honesty. I understand that having someone read your energy can make some feel vulnerable, and I want to reassure you that I offer a judgement-free zone, and will respect you, as you are putting your trust in me.'
            postContent='Readings are meant to bring you clarity, direction, and leave you feeling free.'
            // scratchImg={true}
            lessSpacedHeading={true}
          />
        </div>
      </div>

      {/* <PlainText
        subHeading={"Services"}
        // heading={""}
        btn={false}
        sliderCard={servicesData}
        btnRefState={setSPecificSection}
      /> */}

      <TextContent
        heading="What to Expect in an in-Person Reading"
        content={"We will sit down together and I will provide you with a small cup of coffee to enjoy. The coffee grinds at the bottom of the cup are what I use to read your energy. Although I am using the coffee grinds as a tool to interpret your energy, the insights and information I receive come directly from my intuition. Which is why I also call my readings, intuitive energy readings! The cup is only a tool, the downloads of information that I receive through my intuition clairvoyantly, is really where the magic happens. Readings offer you information about your past, present, and near future."}
        postContent='AT THIS TIME, I AM ONLY OFFERING REMOTE READINGS***'
        btn={true}
        btnText={"Book An Appointment"}
        btnRefTel={true}
        shiftRow={true}
        img="/images/creatives/service-text-1.jpg"
        reviewContent={false}
        btnImg={false}
        fontDark={true}
        // headingBgScratch={true}
        fullWidthContent={true}
        id="what-to-expect-in-person"
      />
      <TextContent
        heading="What to Expect in a Remote Reading"
        content={"A remote reading is exactly the same as an in person reading except that instead of drinking the coffee yourself, I will pour the coffee and flip over the cup to read the grinds on your behalf. There is no need to bring your own cup to a session. Remote readings are available through Zoom or phone call."}
        postContent='AT THIS TIME, I AM ONLY OFFERING REMOTE READINGS***'
        btn={true}
        btnText={"Book An Appointment"}
        btnRefTel={true}
        shiftRow={false}
        img="/images/creatives/service-text-2.jpg"
        reviewContent={false}
        btnImg={false}
        fontDark={true}
        // headingBgScratch={true}
        fullWidthContent={true}
        id="what-to-expect-in-remote"
      />
      {/* <TextContent
       
        content={""}
        btn={false}
        shiftRow={true}
        img="/images/creatives/service-text-3.jpg"
        reviewContent={false}
        btnImg={false}
        fontDark={true}
        headingBgScratch={false}
        fullWidthContent={true}
      /> */}
      <PlainText
       codeHeading="What You Should Know <br class='d-lg-block d-none'> Before Coming into a Reading"
        bulletListData={[
          "Each reading is unique and specific to the current healing you are experiencing and in need of",
          "What I offer in a reading is not my personal advice or opinion, it is strictly what I am receiving from your energy",
          "Please feel free to enter the experience with open energy, and just be you!"
        ]}
        centerBullets={false}
        removePaddingHomeCard={true}
      />

      <ServiceSplitContentFirst
        heading="One-on-One Intuitive Readings"
        content1={"In a one-on-one intuitive reading, I focus exclusively on you. This personalized session is designed to provide deep insights into your individual experiences, challenges, and goals. Whether you’re seeking clarity on personal issues, career decisions, or spiritual guidance, this session is tailored to your unique needs."}
        content2={"Feel free to bring any specific questions or areas of focus to the session. This is your time, and we will concentrate on whatever you need to gain clarity and direction."}
        stylishHeading="What to Expect:"
        splitText={[
          {
            title: "Personalized Insights",
            content: "Receive guidance specifically for your situation and questions."
          },
          {
            title: "Deep Exploration",
            content: "Dive into your personal growth, challenges, and aspirations."
          },
          {
            title: "Confidential Space",
            content: "Enjoy a private and supportive environment to explore whatever is on your mind."
          },
        ]}
        id="one-on-one-intutive"
      />

      <ServiceSplitContentSecond
         heading="Partnered Intuitive Readings"
         content1={"A partnered intuitive reading provides a unique opportunity for you and a partner of your choice—whether that’s a friend, family member, business associate, or significant other—to explore various aspects of your lives together. Whether you want insights into your relationship dynamics or wish to focus on other important areas like career, personal growth, or shared goals, this session is designed to address what matters most to both of you."}
         content2={"Bring specific questions or topics you’d like to address. This session is designed to offer valuable insights into whatever is most important to you both, whether it’s related to your partnership or other aspects of your lives.\n Feel free to choose the service that best suits your needs, and together we’ll create a session that provides the clarity and guidance you’re seeking."}
         stylishHeading="What to Expect:"
         splitText={[
           {
             title: "Flexible Focus",
             content: "Explore any area of interest or concern, not limited to relationship issues."
           },
           {
             title: "Dual Perspectives",
             content: "Gain understanding of how both of your energies and perspectives influence the topics you choose to explore."
           },
           {
             title: "Open Dialogue",
             content: "Both partners should feel comfortable sharing personal information and questions."
           },
         ]}
         id="partner-intutive"
      />

      {/* <ServiceSplitContent
        heading="One-on-One Intuitive Readings"
        // headingBg="/images/icons/plain-text-heading-bg-services.svg"
        content1={"In a one-on-one intuitive reading, I focus exclusively on you. This personalized session is designed to provide deep insights into your individual experiences, challenges, and goals. Whether you’re seeking clarity on personal issues, career decisions, or spiritual guidance, this session is tailored to your unique needs."}
        content2={"Feel free to bring any specific questions or areas of focus to the session. This is your time, and we will concentrate on whatever you need to gain clarity and direction."}
        stylishHeading="What to Expect:"
        splitText={[
          {
            title: "Personalized Insights",
            content: "Receive guidance specifically for your situation and questions."
          },
          {
            title: "Deep Exploration",
            content: "Dive into your personal growth, challenges, and aspirations."
          },
          {
            title: "Confidential Space",
            content: "Enjoy a private and supportive environment to explore whatever is on your mind."
          },
        ]}
        id="one-on-one-intutive"
        bgColor="#B1B6AC"
        textColor="white"
      /> */}
      {/* <ServiceSplitContent
        // bgImg={"/images/creatives/split-content-section.jpg"}
        heading="Partnered Intuitive Readings"
        // headingBg="/images/icons/plain-text-heading-bg-services.svg"
        content1={"A partnered intuitive reading provides a unique opportunity for you and a partner of your choice—whether that’s a friend, family member, business associate, or significant other—to explore various aspects of your lives together. Whether you want insights into your relationship dynamics or wish to focus on other important areas like career, personal growth, or shared goals, this session is designed to address what matters most to both of you."}
        content2={"Bring specific questions or topics you’d like to address. This session is designed to offer valuable insights into whatever is most important to you both, whether it’s related to your partnership or other aspects of your lives.\n Feel free to choose the service that best suits your needs, and together we’ll create a session that provides the clarity and guidance you’re seeking."}
        stylishHeading="What to Expect:"
        splitText={[
          {
            title: "Flexible Focus",
            content: "Explore any area of interest or concern, not limited to relationship issues."
          },
          {
            title: "Dual Perspectives",
            content: "Gain understanding of how both of your energies and perspectives influence the topics you choose to explore."
          },
          {
            title: "Open Dialogue",
            content: "Both partners should feel comfortable sharing personal information and questions."
          },
        ]}
        id="partner-intutive"
        bgColor="#F5F3EF"
      />

      */}
      <FaqSection
        heading="Frequently Asked Questions"
        showImg={true}
        faqData={faqData}
      /> 

      <Footer />
    </div>
  )
}

export default Service